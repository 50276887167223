.topper--page__title {
  background-color: $c-offwhite;
  width: 100%;

  h1 {
    @include h1;
    @include grid-child;

    @include mq($bp-desktop) {
      width: $eight-col;
    }
  }

  &.has-image {
    padding-bottom: $spacing-xl + $spacing-sm;

    @include mq($bp-tablet) {
      padding-bottom: $spacing-xl + $spacing-xl;
    }
  }
}
