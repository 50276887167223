@mixin border-round-sm {
  border-radius: 8px !important;
}

@mixin border-round-lg {
  border-radius: 150px !important;
}

@mixin dotted-line {
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2240%22%20height%3D%221%22%20viewBox%3D%220%200%2040%201%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2.22222%201H0V0H2.22222V1ZM12.2222%201H7.77778V0H12.2222V1ZM22.2222%201H17.7778V0H22.2222V1ZM32.2222%201H27.7778V0H32.2222V1ZM40%201H37.7778V0H40V1Z%22%20fill%3D%22black%22%2F%3E%20%3C%2Fsvg%3E');
  height: 1px;
}
