@mixin alignment {
  @include grid-child;
  @include margin(x auto);

  @include mq($bp-tablet) {
    width: $eight-col;
  }

  @include mq($bp-desktop-xl) {
    width: $six-col;
  }
}

.article__content {
  @include padding($spacing-lg x);

  @include mq($bp-tablet) {
    @include padding($spacing-xxl x);
  }

  > *,
  > figure.wp-block-image,
  > figure.wp-block-pullquote {
    @include alignment;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  > p:first-child:first-letter {
    @include margin(0.05em 0.15em 0 0);
    @include padding(16px);
    border-radius: 8px;
    border: $border-thick $c-black;
    background-color: $c-seafoam;
    float: left;
    font-family: $ff-serif;
    font-size: 3.8em;
    line-height: 0.83em;
  }
}
