.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing-xxl;
}

.pagination__pages {
  display: flex;
}

.pagination__pages-link {
  @include grid-child;
  @include margin(x $spacing-sm);
  @include size(2.5em);
  display: inline-block;
  border: $border-thin $c-black;
  border-radius: 100%;
  text-align: center;
  padding-top: 0.5em;
  transition: color 0.2s, background-color 0.2s;

  @include interact {
    color: $c-white;
    background-color: $c-black;
  }

  @include mq(0, $bp-tablet) {
    display: none;
  }

  &[aria-disabled='true'] {
    pointer-events: none;
    color: $c-gray;
    border-color: $c-gray;
  }
}

.pagination__stepper {
  @include color-link;
  @include grid-child;
  font-weight: $fw-bold;

  &[aria-disabled='true'] {
    pointer-events: none;
    color: $c-gray;
  }
}
