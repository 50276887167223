// ---------------------------------------------------------------------------
// HTML Reset
// ---------------------------------------------------------------------------

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

/* Correct `block` display not defined in IE 6/7/8/9 and Firefox 3. */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
img {
  display: block;
}

/* Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3. */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1;
}

/* Prevent modern browsers from displaying `audio` without controls. Remove excess height in iOS 5 devices. */
audio:not([controls]) {
  display: none;
  height: 0;
}

/* Address styling not present in IE 7/8/9, Firefox 3, and Safari 4. Known issue:no IE 6 support. */
[hidden] {
  display: none !important;
}

/* Correct overflow displayed in IE 9. */
svg:not(:root) {
  overflow: hidden;
}

body {
  line-height: 1;
}

/* Address style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome. */
strong,
b {
  font-weight: bold;
}

em,
i {
  font-style: italic;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

blockquote,
q {
  quotes: none;
}

a {
  color: inherit;
  text-decoration: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Button reset */
button {
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
}

/* Address styling not present in IE 7/8/9, Safari 5, and Chrome. */
abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

/* Address styling not present in Safari 5 and Chrome. */
dfn {
  font-style: italic;
}

/* Address differences between Firefox and other browsers. Known issue:no IE 6/7 normalization. */
hr {
  -moz-box-sizing: content-box; /* stylelint-disable-line property-no-vendor-prefix */
  box-sizing: content-box;
  height: 0;
}

/* Address styling not present in IE 6/7/8/9. */
mark {
  background-color: #ffffff;
  color: #000000;
}

/* Improve readability of pre-formatted text in all browsers. */
pre {
  white-space: normal;
  word-wrap: break-word;
}

/* Address CSS quotes not supported in IE 6/7. */
q {
  quotes: none;
}

/* Address `quotes` property not supported in Safari 4. */
q::before,
q::after {
  content: '';
  content: none;
}

/* Address inconsistent and variable font size in all browsers. */
small {
  font-size: 80%;
}

/* Prevent `sub` and `sup` affecting `line-height` in all browsers. */
sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

s {
  background: linear-gradient(
    180deg,
    transparent 55%,
    currentColor 55%,
    currentColor calc(55% + 1px),
    transparent calc(55% + 1px)
  );
  text-decoration: none;
}

// ---------------------------------------------------------------------------
// Form-Specific Resets
// ---------------------------------------------------------------------------

/* Address `font-family` inconsistency between `textarea` and other form elements. */
html,
button,
input,
select,
textarea {
  font-family: inherit;
}

input,
label,
select,
button,
textarea {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: normal;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
}

button,
input[type='reset'],
input[type='button'],
input[type='submit'],
input[type='checkbox'],
input[type='radio'],
select {
  box-sizing: border-box;
}

input[type='search'] {
  appearance: none;
  background-color: transparent;
  box-sizing: border-box;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  height: 0;
  width: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

button,
input[type='reset'],
input[type='button'],
input[type='submit'] {
  overflow: visible;
  width: auto;
}

/* Hide the up and down arrows on number fields */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
}

input[type='number'] {
  -moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
}

::-webkit-file-upload-button {
  background: none;
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

select[multiple] {
  vertical-align: top;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  background: transparent;
  color: #000000;
}
