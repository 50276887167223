.lazy-img__mod {
  position: relative;
}

.lazy-img__placeholder {
  display: block;
  width: 100%;
}

.lazy-img {
  @include size(100%);
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.2s;

  &.lazyloaded {
    opacity: 1;
  }

  .lazy-img__mod & {
    @include absolute(0);
  }
}
