.newsletter {
  @include padding($spacing-lg x);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $c-seafoam;
}

.newsletter__message {
  @include grid-child;
}

.newsletter__link-container {
  @include grid-child;
  flex-shrink: 0;
}

.newsletter__link {
  @include padding($spacing-sm $spacing-lg);
  @include border-round-sm;
  @include caption-text($weight: $fw-bold);
  background-color: $c-pink;
  border: $border-thin $c-black;
  transition: background-color 0.2s;

  @include interact {
    background-color: $c-black;
    color: $c-white;
  }
}

.footer-container {
  margin-top: $spacing-xxl;
}

.footer {
  @include padding($spacing-lg x);
  background-color: $c-offwhite;
  display: flex;
  flex-flow: row wrap;

  @include mq($bp-desktop) {
    @include padding($spacing-xxl x);
  }

  .page-template-template--home & {
    background-color: $c-white;
  }
}

.footer__section {
  @include grid-child;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: $six-col;

  @include mq(0, $bp-desktop) {
    width: 100%;

    &:first-child {
      margin-bottom: $spacing-xxl;
    }
  }
}

.footer__logo {
  svg {
    @include size(100px, 30px);

    @include mq($bp-tablet) {
      @include size(125px, 30px);
    }
  }
}

.footer__former-title {
  @include caption-text;
  margin-top: $spacing-xs;
}

.footer__headline {
  @include h1;
  margin-top: $spacing-xxl;
  max-width: 800px;
}

.footer__navigation {
  display: flex;
  flex-flow: row wrap;
}

.footer__navigation,
.footer__contact {
  margin-bottom: $spacing-lg;

  @include mq($bp-desktop) {
    margin-bottom: $spacing-xxl;
  }
}

.footer__navigation-col {
  width: 50%;

  @include mq(0, $bp-desktop-xl) {
    margin-bottom: $spacing-xl;
  }

  @include mq($bp-desktop-xl) {
    width: 25%;
  }
}

.footer__navigation-col__link {
  @include margin($spacing-xs x);
  @include caption-text;
  @include text-link($c-underline: transparent);
  width: fit-content;
  display: block;
}

.footer__header {
  @include caption-text($weight: $fw-bold);
  margin-bottom: $spacing-sm;

  @include mq($bp-desktop) {
    margin-bottom: $spacing-md;
  }
}

.footer__contact {
  div {
    @include caption-text;
  }
}

.footer__social-nav {
  margin-bottom: $spacing-lg;
}

.footer__social-links {
  display: flex;
  gap: $spacing-sm;

  li {
    svg {
      @include size(30px);
      color: $c-pink;
    }

    a {
      cursor: pointer;
    }
  }
}

.footer__utilities {
  @include caption-text;
  display: flex;
  flex-flow: row wrap;
  gap: $spacing-xs $spacing-lg;
  margin-bottom: $spacing-sm;

  a {
    @include text-link($c-underline: transparent);
  }
}

.footer__copyright {
  p {
    @include caption-text;
  }
}
