.visually-hidden {
  @include hide;
}

.topper-padding {
  @include padding(200px x $spacing-md x);

  @include mq($bp-desktop) {
    @include padding(340px x $spacing-xl x);
  }
}

.topper-padding-sm {
  @include padding(150px x $spacing-md x);

  @include mq($bp-desktop) {
    @include padding(280px x $spacing-xl x);
  }
}

.topper-padding-xs {
  @include padding(150px x $spacing-md x);

  @include mq($bp-desktop) {
    @include padding(220px x $spacing-xl x);
  }
}

.site-padding {
  @include grid-parent($flow: false);
}

.site-padding-flow {
  @include grid-parent;
}

.rich-text {
  p {
    margin-bottom: $spacing-md;
  }

  a {
    @include text-link;
  }
}

.gutenberg-body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: $spacing-xl;
  }

  h1 {
    @include h1;
  }

  h2 {
    @include h2;
  }

  h3 {
    @include h3;
  }

  h4 {
    @include h4;
  }

  h5 {
    @include h5;
  }

  h6 {
    @include h6;
  }

  p {
    margin-top: $spacing-md;
  }

  a:not(.wp-block-button__link) {
    @include text-link;
  }

  ul {
    @include ul;
    margin-top: $spacing-md;
  }

  ol {
    @include ol;
    margin-top: $spacing-md;
  }

  hr {
    @include margin($spacing-xl x);
    @include dotted-line;
    box-sizing: border-box;
    border: none;

    @include mq($bp-tablet) {
      @include margin($spacing-xxl x);
    }
  }

  div.wp-block-group {
    box-sizing: border-box;

    .wp-block-columns {
      display: flex;
      flex-direction: column;
      gap: 0;
      flex-wrap: nowrap;
      align-items: normal;

      @include mq($bp-tablet-lg) {
        flex-direction: row;
        gap: 2em;
      }

      .wp-block-column {
        flex-basis: 0;
        flex-grow: 1;
      }
    }
  }

  blockquote.wp-block-quote {
    @include margin($spacing-xl x);

    p {
      @include h4($font: $ff-serif);
      font-weight: $fw-reg;
    }

    cite {
      @include caption-text;
      display: block;
      margin-top: $spacing-lg;
    }

    position: relative;
    padding-left: $spacing-xl;

    &::before {
      @include pseudo(0 x x $spacing-sm);
      height: 100%;
      border-right: $border-medium $c-light-pink;
    }
  }

  figure.wp-block-pullquote {
    @include padding(0 x);
    @include margin($spacing-lg x);

    @include mq($bp-tablet) {
      @include margin($spacing-xxxl x);
    }

    > blockquote {
      p {
        @include h3;
        text-align: center;
      }

      cite {
        @include margin($spacing-md auto x auto);
        display: block;
        width: fit-content;
      }

      &::before {
        content: '“';
      }

      &::after {
        content: '”';
        padding-top: $spacing-lg;

        @include mq($bp-tablet) {
          padding-top: $spacing-xl;
        }
      }

      &::before,
      &::after {
        @include h3;
        color: $c-light-pink;
        text-align: center;
        display: block;

        @include mq($bp-mobile) {
          font-size: $fs-xxl;
        }

        @include mq($bp-desktop) {
          font-size: $fs-xxxl;
        }
      }
    }
  }

  figure.wp-block-image {
    @include margin($spacing-lg x);

    figcaption {
      @include margin($spacing-sm x);
      @include caption-text($color: $c-gray);
    }

    &.alignfull {
      width: 100%;
    }
  }

  div.wp-block-buttons {
    @include margin($spacing-xl x $spacing-xxl x);

    @include mq($bp-desktop) {
      margin-bottom: $spacing-xl * 2;
    }

    .wp-block-button {
      a {
        @include padding($spacing-md $spacing-lg);
        @include border-round-sm;
        @include caption-text;
        transition: background-color 0.2s;
        display: none;
      }

      &:nth-child(1) {
        a {
          display: block;
          background-color: $c-pink;
          border: $border-thin $c-black;
          font-weight: $fw-bold;

          @include interact {
            background-color: $c-black;
            color: $c-white;
          }
        }
      }

      &:nth-child(2) {
        a {
          display: block;
          background-color: transparent;
          border: $border-thin $c-black;

          @include interact {
            background-color: $c-black;
            color: $c-white;
          }
        }
      }
    }
  }

  figure.wp-block-gallery {
    $gap: 10px * math.div(100%, 3) * 2;

    @include margin($spacing-lg 0);
    width: 100%;
    align-items: normal;
    position: relative;

    @include mq($bp-tablet-lg) {
      @include margin($spacing-xl 0);
    }

    figure.wp-block-image {
      @include margin(0 x);
      flex-grow: 1;
      width: calc((100% / 2) - 10px * 0.6666667);

      @include mq($bp-tablet-lg) {
        width: calc((100% / 3) - 10px * 0.6666667);
      }

      img {
        flex: 1 0 0%;
        object-fit: cover;
        height: 100%;
      }
    }

    figcaption {
      @include caption-text($color: $c-gray);
      width: 100%;
      text-align: left;
    }
  }

  figure.wp-block-embed-youtube.wp-embed-aspect-16-9 {
    margin-top: $spacing-lg;
    aspect-ratio: 16 / 9;
    width: 100%;

    @include mq($bp-desktop) {
      width: $six-col;
    }

    > .wp-block-embed__wrapper {
      @include size(100%);

      > iframe {
        @include size(100%);
      }
    }
  }
}
