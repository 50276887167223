// Hides text, but is accessible to screen readers. Best used on single element next to an element that will display. IE: title next to an icon.
// $accessible [true] - Triggers accessible clip method. Use "false" for font: 0 method.
//
// @example scss - SCSS
// .item {
//   @include hide();
//   //@include hide(false);
// }

@mixin hide($accessible: true) {

  @if $accessible != true {
    color: transparent;
    font: 0/0 a;
    text-shadow: none;
  }
  @else {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &.focusable:active,
    &.focusable:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
}
