.block-steps {
  @include margin($spacing-xxl x $spacing-xxxl x);
  position: relative;

  &.block-steps {
    width: 100%;
  }
}

.block-steps__list {
  @include mq($bp-desktop) {
    width: $six-col;

    &:hover,
    &:focus-within {
      .block-steps__item {
        .block-steps__number {
          background-color: $c-seafoam;
          color: $c-black;
        }

        .block-steps__image-inner {
          opacity: 0;
          transform: translate(20px);
        }

        @include interact {
          .block-steps__number {
            background-color: $c-black;
            color: $c-white;
          }

          .block-steps__image-inner {
            opacity: 1;
            transform: translate(0px);
          }
        }
      }
    }
  }
}

.block-steps__item {
  &.block-steps__item {
    @include padding(0);
    position: static;
  }

  @include mq(0, $bp-tablet) {
    margin-bottom: $spacing-xxl;
  }

  @include mq($bp-tablet, $bp-desktop) {
    display: flex;
  }

  @include mq($bp-desktop) {
    &:first-child {
      .block-steps__number {
        background-color: $c-black;
        color: $c-white;
      }

      .block-steps__image-inner {
        opacity: 1;
        transform: translate(0px);
      }
    }
  }

  &::before {
    display: none;
  }

  &:last-child {
    .block-steps__number::before {
      display: none;
    }

    .block-steps__meta {
      padding-bottom: 0px;
    }
  }

  @include interact {
    .block-steps__number {
      background-color: $c-black;
      color: $c-white;
    }

    .block-steps__image-inner {
      opacity: 1;
      transform: translate(0px);
    }
  }
}

.block-steps__meta {
  @include mq($bp-tablet) {
    display: flex;
    position: relative;
    padding-bottom: $spacing-xxxl;
  }
}

.block-steps__number {
  @include size(3em);
  flex-shrink: 0;
  border-radius: 100%;
  border: $border-thin $c-black;
  background-color: $c-seafoam;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $spacing-md;
  transition: color 0.2s, background-color 0.2s;

  @include mq(0, $bp-tablet) {
    margin-bottom: $spacing-md;
  }

  @include mq($bp-tablet) {
    &::before {
      @include absolute(0 x x 1.5em);
      content: '';
      border-left: $border-thin $c-black;
      height: 100%;
      z-index: -1;
    }
  }
}

.block-steps__title {
  &.block-steps__title {
    @include h3;
    margin-top: 0.3em;
  }
}

.block-steps__description {
  @include mq($bp-tablet) {
    max-width: 400px;
  }
}

.block-steps__image {
  width: 100%;

  @include mq(0, $bp-tablet) {
    margin-top: $spacing-md;
  }

  @include mq($bp-tablet, $bp-desktop) {
    @include margin(x x $spacing-xxl $spacing-md);
    width: 50%;
    flex-shrink: 0;
  }

  @include mq($bp-desktop) {
    @include absolute(0 0 x x);
    @include size(35%, 100%);
  }
}

.block-steps__image-inner {
  transition: opacity 0.2s, transform 0.2s;

  @include mq($bp-desktop) {
    opacity: 0;
    transform: translate(20px);
    position: sticky;
    top: 25vh;
  }
}
