// Content
// -------
// FOR: Filling pseudo-clases :before, :after with blank content
// WHY: Pseudo classes won't appear unless you fill them with content.
// This mixin includes the difficult-to-recall cross-browser character code for "blank".
// USE: @include content;

@mixin content($content: '\0020') {
  content: '#{$content}';
}

// Pseudo
// -------
// FOR: Quickly pasting essential and commonly used properties that make pseudo classes work
// WHY: Easier than remembering and writing each time. Should cut down on mistakes/debugging.
// USE: @include pseudo;

@mixin pseudo($coordinates: none) {
  @include content;

  @if $coordinates != 'none' {
    @include absolute($coordinates);
  }
}
