// Families
$ff-sans: 'Surt', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$ff-serif: 'New Spirit', serif;

// Weights
$fw-reg: 400;
$fw-med: 500;
$fw-bold: 700;

// Sizes
$fs-xxxs: 12px;
$fs-xxs: 14px;
$fs-xs: 16px;
$fs-sm: 20px;
$fs-md: 24px;
$fs-lg: 28px;
$fs-xl: 36px;
$fs-xxl: 45px;
$fs-xxxl: 60px;
$fs-xxxxl: 72px;

// Line Height
$lh-lg: 1.45;
$lh-md: 1.3;
$lh-sm: 1.2;
$lh-xs: 1.1;
$lh-xxs: 1.05;
