.template--archive__hero {
  margin-bottom: $spacing-xxl;

  @include mq($bp-desktop-xl) {
    margin-bottom: $spacing-xxxxl;
  }

  &__image {
    @include grid-child;
    @include text-link(
      $c-underline: transparent,
      $target: '~ .template--archive__hero__meta h2 a'
    );
    width: 100%;

    @include mq($bp-desktop) {
      width: $six-col;
    }
  }

  &__meta {
    @include grid-child;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 100%;

    @include mq($bp-desktop) {
      @include margin(x auto);
      width: $six-col;
    }

    @include mq($bp-desktop-xl) {
      width: $four-col;
    }

    &-inner {
      position: sticky;
      bottom: $spacing-lg;

      @include mq($bp-desktop-xl) {
        bottom: $spacing-xxxl;
      }
    }
  }

  &__title {
    position: relative;

    h2 {
      @include h1;
      @include padding($spacing-md x);
      max-width: 800px;

      a {
        @include text-link($c-underline: transparent);
      }
    }

    &::after {
      @include pseudo(x x 0 0);
      @include dotted-line;
      @include size(100%, 2px);
    }
  }

  &__category {
    @include color-link;
    display: inline-block;
    font-weight: $fw-bold;
    padding-top: $spacing-md;

    @include mq($bp-tablet) {
      padding-top: $spacing-lg;
    }
  }

  &__date {
    display: block;

    @include mq($bp-tablet) {
      float: right;
      padding-top: $spacing-lg;
    }
  }
}

.template--archive__teases {
  @include grid-parent;

  li {
    @include grid-child;
    width: 100%;

    @include mq(0, $bp-tablet) {
      &:not(:first-child) {
        margin-top: $spacing-lg;
      }
    }

    @include mq($bp-tablet) {
      margin-bottom: $spacing-xl;
      width: $four-col;
    }

    @include mq($bp-desktop-xl) {
      margin-bottom: $spacing-xxl;
      width: $three-col;
    }
  }
}
