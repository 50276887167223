.hero-statistic {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-xxl;
  position: relative;

  @include mq($bp-tablet) {
    margin-top: $spacing-xxxl;
  }

  &:before {
    margin: auto;
    content: '';
    background-color: $c-seafoam;
    opacity: 0.4;
    border-radius: 100%;
    z-index: -1;

    @include mq(0, $bp-tablet) {
      @include size(300px);
      margin-bottom: -240px;
    }

    @include mq($bp-tablet) {
      @include size(500px);
      margin-bottom: -400px;
    }

    @include mq($bp-tablet-lg) {
      @include size(700px);
      margin-bottom: -600px;
    }

    @include mq($bp-desktop) {
      @include size(750px);
      margin-bottom: -600px;
    }

    @include mq($bp-desktop-xl) {
      @include size(900px);
      margin-bottom: -700px;
    }
  }
}

.hero-statistic__topper {
  @include margin(x auto $spacing-xl auto);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include mq($bp-desktop) {
    margin-bottom: $spacing-xxl;
  }
}

.hero-statistic__label {
  @include h4($weight: $fw-reg);
  margin-top: $spacing-lg;
}

.hero-statistic__statistic {
  @include giant-text;

  @include fluid-font(
    $tween-start: 80px $bp-mobile,
    $tween-end: 180px $bp-desktop-xl
  );
}

.hero-statistic__images {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.hero-statistic__img {
  @include grid-child;
  width: $four-col;

  &:nth-child(2) {
    margin-top: $spacing-xl;
  }

  @include mq($bp-desktop) {
    width: $three-col;

    &:nth-child(2) {
      margin-top: $spacing-xxl;
    }
  }
}
