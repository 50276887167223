@import '@splidejs/splide/css/core';

.splide.splide {
  @include margin($spacing-xl x);
  width: 100%;

  &.is-single {
    .splide__arrows {
      display: none;
    }
  }
}

.splide__slide.splide__slide {
  @include padding(0);

  &::before {
    display: none;
  }
}

.splide__arrows {
  @include grid-child;
  @include absolute(50% x x 0);
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 1;

  @include mq(0, $bp-desktop) {
    top: 75%;
  }
}

.splide__arrow {
  @include size(50px);
  background-color: $c-seafoam;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;
  cursor: pointer;

  svg {
    @include size(20px);
  }

  &--prev {
    transform: translateX(-50%) rotate(180deg);
  }

  &--next {
    transform: translateX(50%);
  }

  @include mq(0, $bp-desktop) {
    &--prev {
      transform: translateX(-30%) rotate(180deg);
    }

    &--next {
      transform: translateX(30%);
    }
  }

  @include interact {
    background-color: $c-white;
  }
}

.block-testimonial__inner {
  @include padding($spacing-lg);
  @include border-round-sm;
  height: 100%;
  border: $border-thin $c-black;
  background-color: $c-offwhite;

  &.block-testimonial__inner {
    width: 100%;
  }

  @include mq(0, $bp-desktop) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include mq($bp-desktop) {
    @include padding($spacing-xxl);
  }
}

.block-testimonial__figure {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq($bp-desktop) {
    flex-direction: row;
  }
}

.block-testimonial__quote {
  @include h3;
  text-align: center;

  @include mq($bp-desktop) {
    margin-right: $spacing-xxxl;
    text-align: left;
  }

  &::before {
    content: '“';
  }

  &::after {
    content: '”';
    padding-top: $spacing-lg;

    @include mq($bp-tablet) {
      padding-top: $spacing-xl;
      text-align: right;
    }
  }

  &::before,
  &::after {
    @include h3;
    color: $c-pink;
    display: block;

    @include mq(0, $bp-desktop) {
      text-align: center;
    }

    @include mq($bp-mobile) {
      font-size: $fs-xxl;
    }

    @include mq($bp-desktop) {
      font-size: $fs-xxxl;
    }
  }
}

.block-testimonial__caption {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
}

.block-testimonial__portrait {
  @include size(200px);
  clip-path: circle(50% at 50% 50%);
}

.block-testimonial__name {
  @include caption-text($weight: $fw-bold);
  text-align: center;
}

.block-testimonial__link {
  display: inline-block;
  margin-top: $spacing-xl;
}
