.topper--home {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  @include mq($bp-desktop) {
    flex-direction: row-reverse;
  }
}

.topper--home__hero {
  @include grid-child;
  margin-left: auto;

  @include mq($bp-desktop) {
    width: $eight-col;
  }

  h1 {
    @include h1;
    max-width: 1000px;
  }
}

.topper--home__image {
  margin-top: $spacing-sm;

  @include mq($bp-desktop) {
    margin-top: $spacing-xl;
  }
}

.topper--home__introduction {
  @include grid-child;

  .topper--home__introduction__image-container {
    position: relative;
  }

  @include mq(0, $bp-desktop) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: $spacing-lg;
    width: 100%;

    .topper--home__introduction__image-container {
      overflow-x: hidden;
      width: 100%;
    }

    .topper--home__introduction__image {
      margin-top: $spacing-md;
      width: $six-col;
    }

    .topper--home__introduction__text {
      max-width: 500px;
    }
  }

  @include mq($bp-desktop) {
    width: $three-col;

    .topper--home__introduction__image-container {
      max-width: 200px;
    }

    .topper--home__introduction__text {
      margin-top: $spacing-md;
    }
  }

  @include mq($bp-desktop-xl) {
    .topper--home__introduction__image-container {
      max-width: 300px;
    }
  }

  .topper--home__introduction__stamp {
    @include pseudo(50% 0 x x);
    transform: translate(50%, -50%);
    transform-origin: center;
    z-index: -1;

    @include mq($bp-desktop) {
      @include pseudo(0 0 x x);
      transform: translate(45%, -45%);
    }

    svg {
      @include size(160px);
      animation: spin 20s linear infinite;

      @include mq($bp-mobile-lg) {
        @include size(200px);
      }

      @include mq($bp-tablet-lg) {
        @include size(300px);
      }

      @include mq($bp-desktop) {
        @include size(200px);
      }

      @include mq($bp-desktop-xl) {
        @include size(300px);
      }
    }
  }
}
