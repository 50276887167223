@charset "UTF-8";
@use 'sass:math';

/**
 * Style Name: Nankind Styles
 * Description: Custom styles for Nankind WordPress theme.
 * Author: Gino Jacob
 */

@import 'reset';
@import 'abstracts';
@import 'fonts';
@import 'classes';
@import 'components';
@import 'blocks';
@import 'layouts';

body {
  @include body-text;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
  font-feature-settings: 'ss10';
  overflow-x: hidden;
}

#base {
  position: relative;
}

/* Box model */
*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  @include border-round-sm;
  @include size(100%, auto);
}

a[data-external] {
  cursor: image-set(
        '../img/external-cursor.png' 1x,
        '../img/external-cursor2x.png' 2x
      )
      15 15,
    ne-resize;
}
