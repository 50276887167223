.error__title {
  @include giant-text;
  @include grid-child;

  @include mq($bp-desktop) {
    width: $eight-col;
    margin-left: $four-col;
  }

  span {
    display: block;
  }
}

.error__message {
  @include h4($weight: $fw-reg);
  @include grid-child;
  @include margin($spacing-lg x $spacing-lg x);
  max-width: 500px;

  @include mq($bp-desktop) {
    @include margin($spacing-xl x $spacing-xxl $four-col);
  }
}
