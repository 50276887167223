@mixin alignment {
  @include grid-child;
  margin-right: 0;

  @include mq($bp-tablet, $bp-tablet-lg) {
    width: $nine-col;
    margin-left: 0;
    margin-right: auto;
  }

  @include mq($bp-tablet-lg) {
    margin-left: 0;
    width: $seven-col;
  }

  @include mq($bp-desktop-xl) {
    margin-left: 0;
    width: $five-col;
  }
}

.page__img {
  @include grid-child;
  @include margin(x x $spacing-lg x);
  margin-top: -$spacing-xxl;

  @include mq($bp-tablet) {
    @include margin(x x $spacing-xl x);
    margin-top: -$spacing-xxxl;
    width: $nine-col;
  }

  @include mq($bp-tablet-lg) {
    width: $seven-col;
  }
}

.page__body {
  position: relative;

  @include mq(0, $bp-tablet) {
    @include padding($spacing-lg x);
  }

  @include mq($bp-tablet) {
    @include padding($spacing-xxl x);
  }
}

.page__body__page-navigation {
  @include absolute(0 0 x x);
  @include size(fit-content, 100%);
  min-width: 300px;

  .page-navigation {
    @include grid-child;
    position: sticky;
    top: $spacing-xxl;

    @include mq($bp-desktop-xl) {
      top: $spacing-xxxl;
    }
  }

  @include mq(0, $bp-tablet-lg) {
    display: none;
  }

  @include mq($bp-tablet-lg) {
    @include padding($spacing-xxl x);
    max-width: $four-col;
    margin-left: auto;
  }
}

.page__content {
  > *,
  > figure.wp-block-image,
  > figure.wp-block-pullquote {
    @include alignment;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
