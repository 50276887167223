@mixin grid-parent($flow: true) {
  @include padding(x $grid-padding-mobile);

  @if $flow {
    display: flex;
    flex-flow: row wrap;
  }

  @include mq($bp-tablet) {
    @include padding(s $grid-padding);
  }

  @include mq($bp-desktop) {
    @include padding(x $grid-padding * 4);
  }
}

@mixin grid-child {
  @include padding(x $grid-padding-mobile);

  @include mq($bp-tablet) {
    @include padding(x $grid-padding);
  }
}
