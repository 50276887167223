.topper--sibling-page {
  background-color: $c-seafoam;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}

.topper--sibling-page__meta,
.topper--sibling-page__image {
  @include grid-child;
  width: 100%;

  @include mq($bp-desktop) {
    width: $six-col;
  }
}

.topper--sibling-page__meta {
  display: flex;
  flex-direction: column;
}

.topper--sibling-page__image {
  @include mq(0, $bp-desktop) {
    margin-top: $spacing-md;
  }

  .topper--sibling-page__image-inner {
    height: 100%;
  }
}

.topper--sibling-page__title {
  @include h1;
  position: relative;
  padding-bottom: $spacing-md;
  margin-bottom: $spacing-md;

  @include mq($bp-desktop-xl) {
    @include giant-text;
  }

  &::after {
    @include pseudo(x x 0 0);
    @include dotted-line;
    @include size(100%, 2px);
  }
}

.topper--sibling-page__siblings {
  width: fit-content;
  margin-bottom: $spacing-xl;

  &:hover,
  &:focus-within {
    .topper--sibling-page__sibling-item a {
      opacity: 0.5;

      &::before {
        background-color: $c-black;
      }

      &:hover,
      &:focus {
        opacity: 1;

        > span {
          transform: translateX($spacing-xs);
        }

        &::before {
          display: inline;
          clip-path: inset(0 0 0 0);
          background-color: $c-pink;
        }
      }
    }
  }
}

.topper--sibling-page__sibling-item {
  a {
    @include padding($spacing-xxs x);
    @include h4($weight: $fw-reg);
    display: flex;
    align-items: center;
    transition: opacity 0.2s;
    opacity: 0.5;

    > span {
      transition: transform 0.2s, opacity 0.2s;
    }

    &::before {
      @include size(0.6em);
      display: none;
      content: '';
      border-radius: 100%;
      background-color: $c-pink;
      transition: clip-path 0.2s, background-color 0.2s;
      clip-path: inset(0 100% 0 0);
    }

    &[aria-current='page'],
    &:hover,
    &:focus {
      opacity: 1;

      > span {
        transform: translateX($spacing-xs);
      }

      &::before {
        display: inline;
        clip-path: inset(0 0 0 0);
        background-color: $c-pink;
      }
    }
  }
}

.topper--sibling-page__cta {
  @include padding($spacing-sm $spacing-lg);
  @include border-round-sm;
  background-color: $c-pink;
  border: $border-thin $c-black;
  transition: background-color 0.2s;
  display: inline-block;
  width: fit-content;

  @include interact {
    background-color: $c-black;
    color: $c-white;
  }

  @include mq($bp-desktop) {
    .has-image & {
      margin-top: auto;
    }
  }
}
