@mixin ul-base($level: 1) {
  @if $level == 1 {
    list-style: none;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    li {
      @include padding($spacing-xs x);
      padding-left: 1.1em;
      position: relative;

      &::before {
        @include pseudo(0.95em x x 0.1em);
        @include size(8px);
        background-color: $c-light-pink;
        border-radius: 100%;

        @include mq($bp-desktop) {
          top: 0.9em;
        }
      }
    }
  } @else if $level == 2 {
    margin-top: $spacing-sm;

    li {
      &::before {
        background-color: transparent;
        border: $border-thin $c-light-pink;
      }
    }
  } @else if $level == 3 {
    margin-top: $spacing-sm;

    li {
      &::before {
        background-color: $c-light-pink;
        border-radius: 0;
      }
    }
  }
}

@mixin ul {
  @include ul-base(1);

  ul {
    @include ul-base(2);

    ul {
      @include ul-base(3);

      ul {
        @include ul-base(1);

        ul {
          @include ul-base(2);

          ul {
            @include ul-base(3);
          }
        }
      }
    }
  }
}
