.page-navigation {
  display: none;
  animation: fade-in 0.2s;
  width: fit-content;
}

.page-navigation__title {
  @include h6($font: $ff-serif);
}

.page-navigation__links {
  margin-top: $spacing-xs;

  li {
    @include padding($spacing-sm-md x);
    @include caption-text;
    position: relative;

    &::after {
      @include pseudo(x x 0 0);
      @include dotted-line;
      width: 100%;
    }

    a {
      transition: color 0.2s;
      @include interact {
        color: $c-pink;
      }
    }
  }
}
