$c-black: #000000;
$c-white: #ffffff;
$c-offwhite: #f5f2ed;
$c-gray: #808080;
$c-seafoam: #d2e7dc;

$c-extra-light-pink: #ffbec9;
$c-light-pink: #ffa7a8;
$c-pink: #ff57ae;

$c-yellow: #ffdf54;
