.topper--article {
  background-color: $c-offwhite;
}

.topper--article__category {
  @include padding($spacing-xs $spacing-sm);
  @include border-round-sm;
  @include caption-text($weight: $fw-bold);
  @include margin(x auto);
  border: $border-thin $c-black;
  transition: background-color 0.2s;
  width: fit-content;
  display: block;

  @include interact {
    background-color: $c-black;
    color: $c-white;
  }
}

.topper--article__title {
  @include h1;
  @include margin($spacing-xxl x);
  text-align: center;

  @include mq(0, $bp-desktop) {
    @include margin($spacing-lg x);
  }
}

.topper--article__inner {
  @include grid-child;

  @include mq($bp-tablet) {
    @include margin(x auto);
    width: $eight-col;
  }

  @include mq($bp-desktop-xl) {
    width: $six-col;
  }
}

.topper--article__image {
  margin-top: $spacing-sm;

  ~ .topper--article__date {
    text-align: left;
  }
}

.topper--article__date {
  @include caption-text;
  display: block;
  margin-top: $spacing-sm;
  text-align: center;
}
