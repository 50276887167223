.skip-to-content {
  @include size(1px, 1px);
  color: $c-black;
  left: -999px;
  overflow: hidden;
  position: absolute;
  text-decoration: none;
  z-index: $z-below-base;

  &:focus,
  &:active {
    @include padding($spacing-md);
    @include absolute(0 x x 0);
    @include size(auto, auto);
    background-color: $c-seafoam;
    z-index: $z-above-everything;
  }
}
