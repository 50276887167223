.site-navigation {
  @include absolute(0 x x 0);
  @include padding($spacing-lg x);
  width: 100%;
}

.site-navigation__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-navigation__logo {
  @include grid-child;
  width: $four-col;

  svg {
    @include size(100px, 30px);

    @include mq($bp-tablet) {
      @include size(125px, 30px);
    }
  }
}

.site-navigation__links {
  @include margin(x auto x 0);
  @include grid-child;
  display: flex;
  align-items: center;
  gap: $spacing-xl;

  @include mq(0, $bp-desktop) {
    display: none;
  }

  @include mq($bp-desktop-xl) {
    gap: $spacing-xxl;
  }
}

.site-navigation__link {
  @include text-link($c-underline: transparent);
  font-weight: $fw-med;
  white-space: nowrap;
}

.site-navigation__cta {
  @include padding($spacing-sm $spacing-lg);
  @include border-round-sm;
  @include caption-text($weight: $fw-bold);
  background-color: $c-pink;
  border: $border-thin $c-black;
  transition: background-color 0.2s;
  margin-left: $spacing-xxl;

  @include mq(0, $bp-desktop) {
    display: none;
  }

  @include interact {
    background-color: $c-black;
    color: $c-white;
  }
}

.site-navigation__menu-toggle {
  @include grid-child;
  width: fit-content;

  button {
    @include padding(6px);
    background-color: $c-white;
    border-radius: 100%;
  }

  @include mq($bp-desktop) {
    display: none;
  }

  svg {
    @include size(24px);
  }
}
