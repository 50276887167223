.menu__modal {
  @include fixed(0);
  background-color: $c-offwhite;
  min-height: 100vh;
  overflow-y: scroll;
  width: 100vw;
  z-index: $z-menu;
  display: flex;
  flex-direction: column;
}

.menu__top {
  @include padding($spacing-lg x);
  @include size(100%, fit-content);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu__logo {
  @include grid-child;

  svg {
    @include size(100px, 30px);

    @include mq($bp-tablet) {
      @include size(125px, 30px);
    }
  }
}

.menu__close {
  @include grid-child;
  width: fit-content;

  button {
    @include padding(6px);
    background-color: $c-white;
    border-radius: 100%;
  }

  svg {
    @include size(24px);
  }
}

.menu__item {
  @include padding($spacing-md x);
  border-bottom: $border-thin hsla(0, 0%, 9%, 0.05);
  opacity: 0;
  animation: fade-up 0.2s forwards;
}

.menu__links {
  margin-top: auto;
}

.menu__link {
  @include h1;
  @include grid-child;
}

.menu__cta {
  @include margin(2.5vw x);

  a {
    @include grid-child;
    @include padding($spacing-md x);
    @include h2;
    @include border-round-sm;
    border: $border-thin $c-black;
    display: block;
    background-color: $c-pink;
    text-align: center;
  }
}
