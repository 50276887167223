@mixin ol-base($level: 1) {
  @if $level == 1 {
    counter-reset: counter;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    li {
      @include padding($spacing-xs x);
      padding-left: 1.1em;
      position: relative;

      &::before {
        @include pseudo(0.85em x x 0.1em);
        color: $c-light-pink;
        font-weight: $fw-bold;
        font-size: 0.8em;
        content: counter(counter) '.';
        counter-increment: counter;

        @include mq($bp-tablet) {
          top: 0.8em;
        }

        @include mq($bp-desktop) {
          top: 0.7em;
        }
      }
    }
  } @else if $level == 2 {
    counter-reset: counter;

    > li {
      &::before {
        content: counter(counter, lower-alpha) '.';
      }
    }
  } @else if $level == 3 {
    counter-reset: counter;

    > li {
      &::before {
        content: counter(counter, upper-roman) '.';
      }
    }
  }
}

@mixin ol {
  @include ol-base(1);

  ol {
    @include ol-base(2);

    ol {
      @include ol-base(3);

      ol {
        @include ol-base(1);

        ol {
          @include ol-base(2);

          ol {
            @include ol-base(3);
          }
        }
      }
    }
  }
}
