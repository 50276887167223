@mixin body-text(
  $color: inherit,
  $font: $ff-sans,
  $weight: $fw-reg,
  $lh: $lh-lg
) {
  @include fluid-font(
    $tween-start: $fs-xs $bp-mobile,
    $tween-end: $fs-sm $bp-desktop-lg
  );
  color: $color;
  font-family: $font;
  font-weight: $weight;
  line-height: $lh;
  letter-spacing: -0.07em;
}

@mixin caption-text(
  $color: inherit,
  $font: $ff-sans,
  $weight: $fw-reg,
  $lh: $lh-lg
) {
  @include fluid-font(
    $tween-start: $fs-xxs $bp-mobile,
    $tween-end: $fs-xs $bp-desktop-lg
  );
  color: $color;
  font-family: $font;
  font-weight: $weight;
  line-height: $lh;
  letter-spacing: -0.06em;
}

@mixin giant-text(
  $color: inherit,
  $font: $ff-serif,
  $weight: $fw-reg,
  $lh: $lh-xs
) {
  @include fluid-font(
    $tween-start: 72px $bp-mobile,
    $tween-end: 108px $bp-desktop-xl
  );
  color: $color;
  font-family: $font;
  font-weight: $weight;
  line-height: $lh;
  letter-spacing: -0.02em;
}

// ---------------------------------------------------------------------------
// Headings
// ---------------------------------------------------------------------------
@mixin h1($color: inherit, $font: $ff-serif, $weight: $fw-reg, $lh: $lh-sm) {
  @include fluid-font(
    $tween-start: $fs-xl $bp-mobile,
    $tween-end: $fs-xxxxl $bp-desktop-lg
  );
  color: $color;
  font-family: $font;
  font-weight: $weight;
  line-height: $lh;
  letter-spacing: -0.02em;
}

@mixin h2($color: inherit, $font: $ff-serif, $weight: $fw-reg, $lh: $lh-xxs) {
  @include fluid-font($tween-start: $fs-lg 400px, $tween-end: $fs-xxl 1400px);
  color: $color;
  font-family: $font;
  font-weight: $weight;
  line-height: $lh;
  letter-spacing: -0.02em;
}

@mixin h3($color: inherit, $font: $ff-serif, $weight: $fw-reg, $lh: $lh-sm) {
  @include fluid-font($tween-start: $fs-md 400px, $tween-end: $fs-xl 1400px);
  color: $color;
  font-family: $font;
  font-weight: $weight;
  line-height: $lh;
  letter-spacing: -0.02em;
}

@mixin h4($color: inherit, $font: $ff-sans, $weight: $fw-bold, $lh: $lh-md) {
  @include fluid-font($tween-start: $fs-sm 400px, $tween-end: $fs-lg 1400px);
  color: $color;
  font-family: $font;
  font-weight: $weight;
  line-height: $lh;
  letter-spacing: -0.02em;
}

@mixin h5($color: inherit, $font: $ff-sans, $weight: $fw-bold, $lh: $lh-sm) {
  @include fluid-font($tween-start: $fs-xs 400px, $tween-end: $fs-sm 1400px);
  color: $color;
  font-family: $font;
  font-weight: $weight;
  line-height: $lh;
  text-transform: uppercase;
  letter-spacing: 0px;
}

@mixin h6($color: inherit, $font: $ff-sans, $weight: $fw-bold, $lh: $lh-md) {
  @include fluid-font($tween-start: $fs-xs 400px, $tween-end: $fs-sm 1400px);
  color: $color;
  font-family: $font;
  font-weight: $weight;
  letter-spacing: 0.015em;
  line-height: $lh;
  letter-spacing: 0px;
}
