.announcement {
  @include padding($spacing-sm x);
  background-color: $c-seafoam;
  border-bottom: $border-thin $c-black;
}

.announcement__asterisk {
  @include size(1em);
  fill: $c-pink;
  transform: translateY(2px);
  margin-right: $spacing-xxs;

  @include mq($bp-tablet) {
    margin-right: $spacing-xs;
  }
}

.announcement__content {
  @include grid-child;
  @include caption-text;
}
