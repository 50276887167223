$spacing-xxs: 5px;
$spacing-xs: 8px;
$spacing-xs-sm: 10px;
$spacing-sm: 12px;
$spacing-sm-md: 15px;
$spacing-md: 18px;
$spacing-lg: 24px;
$spacing-xl: 36px;
$spacing-xxl: 60px;
$spacing-xxxl: 120px;
$spacing-xxxxl: 160px;

// Grid
$grid-gutter: 20px;
$grid-padding: math.div($grid-gutter, 2);
$grid-padding-tablet: 3vw;
$grid-padding-mobile: 2.5vw;
