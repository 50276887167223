.statistics.statistics {
  @include margin($spacing-xxl x);
  width: 100%;

  @include mq($bp-tablet) {
    @include margin($spacing-xxxl x);
  }

  .single-post &,
  .page-template-default & {
    @include margin($spacing-xl x $spacing-xxxl x);
  }
}

.statistics__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .page-template-default & {
    justify-content: flex-start;
  }
}

.statistics__item {
  @include padding($spacing-lg x);
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @include mq($bp-tablet) {
    @include padding($spacing-md x);
    align-items: center;
    min-width: 320px;

    .has-3 & {
      width: $four-col;
    }

    .has-2 &,
    .has-4 & {
      width: $three-col;
    }

    .page-template-default & {
      align-items: flex-start;
    }
  }
}

.statistics__label {
  font-weight: $fw-med;
  margin-top: $spacing-sm;

  .page-template-default & {
    text-align: left;
  }

  @include mq($bp-tablet) {
    text-align: center;

    .page-template-default & {
      text-align: left;
    }
  }
}

.statistics__statistic {
  @include h1;
  padding-bottom: $spacing-sm;
  border-bottom: 1px dashed $c-black;
  width: 100%;
  text-align: center;

  .page-template-default & {
    text-align: left;
  }

  @include mq(0, $bp-tablet) {
    text-align: left;
  }

  @include mq($bp-desktop-xxl) {
    @include giant-text;
  }
}
