$one-third: math.div(100, 3);
$two-third: $one-third * 2;

// Columns
$one-col: 8.3333333333333%;
$two-col: 16.6666666666666%;
$three-col: 25%;
$four-col: 33.3333333333333%;
$five-col: 41.6666666666666%;
$six-col: 50%;
$seven-col: 58.3333333333333%;
$eight-col: 66.6666666666666%;
$nine-col: 75%;
$ten-col: 83.3333333333333%;
$eleven-col: 91.6666666666666%;
$twelve-col: 100%;
