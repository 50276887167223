.tease--post {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tease--post__link {
  @include text-link(
    $c-underline: transparent,
    $target: '.tease--post__title > span'
  );
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  &::after {
    @include pseudo(0 x x 0);
    @include size(100%);
  }
}

.tease--post__title {
  @include h4($font: $ff-serif, $weight: $fw-reg);
  width: fit-content;
}

.tease--post__image {
  margin-bottom: $spacing-sm;
  order: -1;
}

.tease--post__category {
  @include caption-text($weight: $fw-bold);
  @include color-link;
  width: fit-content;
  margin-top: $spacing-xxs;
  z-index: 1;

  & + .tease--post__date {
    margin-top: 0px;
  }
}

.tease--post__date {
  @include caption-text;
  margin-top: $spacing-xxs;
}
