@mixin text-link(
  $c-underline: $c-pink,
  $c-highlight: $c-extra-light-pink,
  $target: null
) {
  @include with-target($target) {
    background: linear-gradient(to right, $c-underline, $c-underline),
      linear-gradient(to right, $c-highlight, $c-highlight);
    background-size: 100% 0.1em, 100% 0em;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 0.2s;
  }

  @include interact {
    @include with-target($target) {
      background-size: 100% 0, 100% 0.5em;
    }
  }
}

@mixin color-link($c-hover: $c-gray, $target: null) {
  transition: color 0.2s;

  @include interact {
    @include with-target($target) {
      color: $c-hover;
    }
  }
}
