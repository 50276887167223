.home-actions {
  @include margin($spacing-xxl x);

  @include mq($bp-desktop) {
    @include margin($spacing-xxxl x);
  }
}

.home-actions__heading {
  margin-bottom: $spacing-xl;

  h2 {
    @include grid-child;
    @include h2;
    max-width: 700px;
  }
}

.home-actions__action {
  border-top: $border-thin $c-black;
  transition: background-color 0.2s;

  &:last-child {
    border-bottom: $border-thin $c-black;
  }

  &:hover,
  &:focus-within {
    background-color: $c-seafoam;

    .home-actions__action-arrow {
      transform: translateX(0px);
    }
  }
}

.home-actions__action-link {
  @include padding($spacing-md x);
  display: flex;
  align-items: center;
  position: relative;

  @include mq($bp-tablet-lg) {
    @include padding($spacing-xl x);
  }
}

.home-actions__action__title {
  @include grid-child;
  flex-shrink: 0;

  h3 {
    @include h4($font: $ff-serif, $weight: $fw-reg);

    @include mq($bp-desktop-lg) {
      @include h3;
    }
  }

  @include mq($bp-tablet-lg) {
    width: math.div(100%, 3);
  }
}

.home-actions__action__description {
  @include grid-child;

  @include mq(0, $bp-tablet-lg) {
    display: none;
  }

  @include mq($bp-desktop-lg) {
    @include h4($weight: $fw-reg);
  }

  p {
    color: $c-gray;
  }
}

.home-actions__action-arrow {
  @include grid-child;
  @include size(24px);
  margin-left: auto;
  transition: transform 0.2s;
  transform: translateX(-10px);

  svg {
    @include size(24px);
  }

  @include mq($bp-desktop-lg) {
    @include size(32px);

    svg {
      @include size(32px);
    }
  }
}
